import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hello folks,`}</p>
    <p>{`We all need to start somewhere when learning to code. Everyone has a reason to start this journey but everyone gets confused on where to start.`}</p>
    <p>{`Let's talk about which programming language you should learn first.`}</p>
    <p>{`Common questions that I often get on social media and during my days as a software engineer:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Which programming language should I learn first?
I am a student: they are teaching us Java but everyone tells me to learn Python, Javascript, ...
Which language should I learn to get a job?`}</p>
    </blockquote>
    <p>{`Do these questions sound familiar to you?`}</p>
    <p>{`I have both a short answer and a long answer. The short answer is more subjective.
I worked with multiple programming languages throughout my career. Programming languages like Visual Basic (remember this one!?!?), C#, PHP, Java, Python, Javascript, C++.
So, the answer is based on my experience in the last 15 years as a software engineer and my personal taste.`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/Yny83Y-8oWY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <p>{`So, which programming language should you learn first?
My short answer is `}<b>{`Python`}</b>{` and `}<b>{`Javascript`}</b>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--0UTZOxro--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://images.unsplash.com/photo-1543013309-0d1f4edeb868%3Fixlib%3Drb-1.2.1%26ixid%3DeyJhcHBfaWQiOjEyMDd9%26auto%3Dformat%26fit%3Dcrop%26w%3D2646%26q%3D80",
        "alt": "Image"
      }}></img></p>
    <h2>{`Javascript`}</h2>
    <p>{`Javascript is the programming language that is powering the web.
The syntax of Javascript is quite easy to learn and you can start using this programming language without setting up any development environment.`}</p>
    <p>{`Just open your favorite browser, and open the developer console.
That's all you need to start writing Javascript code.`}</p>
    <p>{`Ok, that's not how you would write production code, but if you are just learning your first programming languages and you need a Javascript playground, that's all you need to start writing code in Javascript!`}</p>
    <p>{`In Web Development, you need the frontend and backend part of the website. Learning Javascript can give you the "superpower" of being able to write both sides with just one programming language using something called Node.JS, which is actually Javascript on the server-side.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--wEpK70y8--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://images.unsplash.com/photo-1546992772-3318f1f3a1be%3Fixlib%3Drb-1.2.1%26ixid%3DeyJhcHBfaWQiOjEyMDd9%26auto%3Dformat%26fit%3Dcrop%26w%3D2010%26q%3D80",
        "alt": "Image"
      }}></img></p>
    <h2>{`Python`}</h2>
    <p>{`Another programming language that I would recommend is called Python.
Python is one of the fast-growing programming languages out there and it can be used for Web Development, Data Science, Machine Learning, Automation.`}</p>
    <p>{`The simplicity in its syntax is one of the best parts of this programming language and actually, the thing that made me fall in love with it.`}</p>
    <p>{`The community is huge and there are so many job opportunities using Python and making it a worthy "first" programming language to learn.`}</p>
    <p>{`That's my personal short answer based on my experience, but should you really learn Python and Javascript first? Maybe yes, maybe not!`}</p>
    <p>{`Everyone has different needs and goals on why they are learning code, so there is no magic first programming language that would be good for anyone.`}</p>
    <h2>{`BEFORE CHOOSING YOUR FIRST PROGRAMMING LANGUAGE`}</h2>
    <p>{`There are 3 points to take into account when deciding your first programming language and I am sharing my strategy on how I would go choosing my first programming language if I would start my career in Software Engineering today.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--qJS9OWy3--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://images.unsplash.com/photo-1529400971008-f566de0e6dfc%3Fixlib%3Drb-1.2.1%26ixid%3DeyJhcHBfaWQiOjEyMDd9%26auto%3Dformat%26fit%3Dcrop%26w%3D2550%26q%3D80",
        "alt": "Image"
      }}></img></p>
    <h2>{`JOB MARKET`}</h2>
    <p>{`The job market is the first point to take into consideration,
Most of you are learning to code for a different reason. Some of you are looking to find a job in this field and it is totally understandable. There are so many opportunities in software engineering that I see many of you have this as the main reason to start learning to code.`}</p>
    <p>{`The job market depends on your own location and industry you want to work in.
Different countries have different demands for some programming languages. Same when we talk about the industries you might work in: working as a software engineer in oil & gas might require experience than working in Banking or the Gaming industry.`}</p>
    <p>{`If you want to work in the Gaming Industry, maybe learning C++, C# could be two good options as the first programming language.`}</p>
    <p>{`If you want to work in the Banking Industry, learning Java is the first option for you.`}</p>
    <p>{`If you want to build mobile apps for a living, Swift / Objective-C are the options for iOS and Java / Kotlin for Android.`}</p>
    <p>{`Do you want to work in Data Science and Machine Learning? Python could be a good bet as the first programming language to learn.`}</p>
    <p>{`Understand the kind of job you want to get and the technologies required for it, that's a good way to understand the programming language that you would learn first.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--5uwPbuPq--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://images.unsplash.com/photo-1555774698-0b77e0d5fac6%3Fixlib%3Drb-1.2.1%26ixid%3DeyJhcHBfaWQiOjEyMDd9%26auto%3Dformat%26fit%3Dcrop%26w%3D2550%26q%3D80",
        "alt": "Image"
      }}></img></p>
    <h2>{`WHAT DO YOU WANT TO BUILD?`}</h2>
    <p>{`If you are not looking for a job, learning to code might just be a pure pleasure for you at the moment.
In that case, building project-based learning can be a good option.`}</p>
    <p>{`What does it mean? Instead of only relying on a tutorial, you try to build a project with a given programming language you choose.`}</p>
    <p>{`Let me ask you: what do you want to build?`}</p>
    <ul>
      <li parentName="ul">{`A Website? You should start learning Javascript in combination with HTML / CSS for the frontend part.`}</li>
      <li parentName="ul">{`A Mobile app: You should choose Java or Kotlin for building Android apps and Swift / Objective-C for iOS app. If you want to build cross-platform apps, you could choose to learn Javascript using React Native or Dart using Flutter.`}</li>
      <li parentName="ul">{`Want to build a game? You could choose Swift for the iOS game but probably I would choose to learn C# using Unity.`}</li>
    </ul>
    <p>{`Based on what you want to build, you might choose a different programming language.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--97Y-j_1E--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://images.unsplash.com/photo-1561701033-1d1a4231860f%3Fixlib%3Drb-1.2.1%26ixid%3DeyJhcHBfaWQiOjEyMDd9%26auto%3Dformat%26fit%3Dcrop%26w%3D934%26q%3D80",
        "alt": "Image"
      }}></img></p>
    <h2>{`EASE OF LEARNING`}</h2>
    <p>{`Last but not least, it is the ease of learning a programming language. Some programming languages might be easier to learn and work with than others. Let me explain...`}</p>
    <p>{`Whenever you are solving and trying to solve a problem in software engineering, you have two factors to take into consideration:`}</p>
    <ul>
      <li parentName="ul">{`Problem Solving: Each problem that you are trying to solve has a certain logical complexity. It is your job to understand what will be the steps for solving the problem.`}</li>
      <li parentName="ul">{`Syntax complexity: Each programming language has its own syntax. Some can be easier to learn than others.`}</li>
    </ul>
    <p>{`When learning to code, it is wise to choose programming languages with an easier syntax complexity. For example, learning the syntax and how you write software in Python could be easier than Java.`}</p>
    <h2>{`WHICH PROGRAMMING LANGUAGE SHOULD I LEARN FIRST?`}</h2>
    <p>{`My short answer on which programming language to learn first was Python and Javascript. As I said, it is based on my previous experience but I think they are quite easy to learn and great programming languages to work with offering so many opportunities for your career.`}</p>
    <p>{`But, you should choose the right programming languages for you and your needs. That's why I suggested you to take into account 3 valuable points: job market, what you want to build and ease of learning.`}</p>
    <p>{`Let me know in the comment below which programming language you are learning and how do you feel about it.`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/Yny83Y-8oWY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <p>{`Well, the last thing: If you arrived here, thank you so much for your support and time that you spend on this page.`}</p>
    <p>{`If you enjoyed this story, please click the like button and share it to help others find it! Feel free to leave a comment below.`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      